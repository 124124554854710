const en = {
  login: "Login",
  NA: "N/A",
  email: "Email",
  state: "State",
  zipcode: "Zip Code",
  feedyardinternalAuditor: "Internal Auditor",
  feedyardexternalAuditor: "External Auditor",
  feedyardinternalReviewer: "Internal Reviewer",
  internal: "Internal",
  external: "External",
  feedyardexternalReviewer: "External Reviewer",
  feedyarddefaultactivites: "Default Activites",
  feedlotmanager: "Feedlot Manager",
  phone: "Phone",
  delete: "Delete",
  feedyardmode: "Feedyar Mode",
  trainer: "Trainer",
  feedyardZipPlaceholder: "Enter feedyard zip code here",
  feedyardPhonePlaceholder: "Enter feedyard phone number here",
  feedyardNarcPlaceholder: "Enter feedyar NARC here",
  narc: "NARC",
  na: "N/A",
  announced: "Announced",
  unAnnounced: "Un-Announced",
  submittedToReviewer: "Submitted to reviewer",
  submitted: "Submitted",
  submittedToFeedyard: "Submitted to feedyard",
  receivedInFeedyard: "Received in feedyard",
  address: "Address",
  password: "Password",
  currentPassword: "Current Password",
  enterCurrentPassword: "Enter current password",
  currentPassowrdRequired: "Current Password is Required",
  newConfirmPasswordMusthMatch:
    "New password and confirmation password must match",
  passwordChangedSuccessfully:
    "Password Changed Successfully. Please login again to continue",
  passwordChanged: "Password Changed",
  currentPasswordIncorrect: "Your current password is incorrect.",
  errorInChangingPassword: "Error in changing password",
  newPassword: "New Password",
  enterNewPassword: "Enter new password",
  newPassowrdRequired: "New Password is Required",
  confirmNewPassword: "Confirm New Password",
  reEnterNewPassword: "Re-enter new password",
  confirmNewPassowrdRequired: "Confirm New Password is Required",
  passwordContainTenCharacter: "Password must contain atleast ten characters",
  passwordContainUppercase: "Password must contain one uppercase letter",
  passwordContainLowercase: "Password must contain one lower case letter",
  passwordContainDigit: "Password must contain one number",
  dashboard: "Dashboard",
  Required: "Required",
  InvalidEmail: "Invalid Email ID",
  slogan1: "VERIFIED",
  slogan2: "TRUSTED",
  slogan3: "SUSTAINABLE",
  welcomeTo: "Welcome to",
  feedyardAddressPlaceholder: "Enter feedyard address here",
  progressiveBeef: "Progressive Beef",
  username: "Username",
  name: "Name",
  role: "Role",
  status: "Status",
  updatedStatus: "Updated Status",
  in_Active: "In-Active",
  action: "Action",
  serverError: "Server Error",
  pbAdmin: "PB Admin",
  ok: "OK",
  notOk: "Not OK",
  notProvided: "Not Provided",
  profile: "Profile",
  logout: "Logout",
  sureLogout: "Are you sure you want to logout?",
  tagAlreadyExist: "Tag already exist",
  noTagFound: "No tags found matching 'SearchResult'",
  copyright: "Copyright",
  progressiveBeefCopyright: "Progressive Beef LLC. All rights reserved.",
  serverErrorMessage:
    "There is a server error fetching data. Please refresh again.",
  refresh: "Refresh",
  possiblePoints: "Possible Points",
  noDataFound: "No Data Found",
  noUserFound: "No user found matching",
  noAuditFound: "No audits found matching",
  noSectionFound: "No section found",
  noFeedyardFound: "No feedyard found matching",
  noLockAccount: "There are no locked account(s) in the system at this time.",
  loginToContinue: "Login to Continue",
  userPlaceholder: "Enter username here",
  passwordPlaceholder: "Enter password here",
  successfullyLogin: "Logged in Successfully",
  invalidLogin: "Username and/or Password Incorrect",
  errorInLogin: "You need to have an active internet connection.",
  serverError:
    "Some error occurred while processing. Please contact your system administrator",
  feedyardRank: "Feedyard Rank",
  errorFeedyardRankList: "Error in showing feedyard ranks.",
  addFeedyard: "Add Feedyard",
  complianceRate: "Compliance Rate",
  complianceCalculator: "Compliance Calculator",
  fx: "fx",
  forgetPassword: "Forgot your password?",
  allowInRank: "Allow in Rank",
  addSingleUser: "Add Single User",
  singleUser: "Single User",
  addFeedyard: "Add Feedyard",
  programManuals: "Program Manuals",
  manageProgramManuals: "Manage Program Manuals",
  Required: "Required",
  InvalidEmail: "Invalid Email ID",
  feedyards: "Feedyards",
  versionNumber: "Version Number",
  manageFeedyards: "Manage Feedyard(s)",
  programManual: "Program Manual",
  manageUsers: "Manage Users",
  published: "Published",
  draft: "Draft",
  bin: "Bin",
  users: "Users",
  activityNumber: "Activity Number",
  title: "title",
  tags: "Tag(s)",
  complianceRate: "Compliance Rate",
  consumedFeedyard: "Consumed Feedyard",
  revisionDateTime: "Revision Date & Time",
  revisionDate: "Revision Date",
  addAudit: "Add Audit",
  addAuditSection: "Add Audit Section",
  editAuditSection: "Edit Audit Section",
  duplicateAuditSection: "Duplicate Audit Section",
  addSection: "Add Section",
  addTable: "Add Table",
  editTable: "Edit Table",
  tableName: "Table Name",
  tableNameLengthValidation: "Table Name must be maximum 250 characters.",
  columnNameLengthValidation: "Column Name must be maximum 250 characters.",
  assignToFeedyard: "Assign to Feedyard",
  assigned: "Assigned",
  editAudit: "Edit Audit",
  addUsers: "Add User",
  bulkAction: "Bulk Action",
  editUsers: "Edit User",
  userDetail: "User Detail",
  manageActivities: "Manage Activities",
  activites: "Activities",
  videos: "Videos",
  reports: "Reports",
  create: "Create",
  COMPLIANCE_REPORTS: "Compliance Reports",
  CUSTOM_REPORTS: "Custom Reports",
  AUDIT_REPORTS: "Audit Reports",
  audits: "Audits",
  manageAudits: "Manage Audits",
  addDocument: "Add Document",
  docNo: "Doc No.",
  type: "Type",
  updateStatus: "Update Status",
  importInFeedyard: "Import In Feedyard",
  lastRevisionDateTime: "Last Revision Date & Time",
  lastRevisionDate: "Last Revision Date",
  addActivity: "Add Activity",
  editActivity: "Edit Activity",
  activityDetail: "Activity Detail",
  active: "Active",
  auditDate: "Audit Date",
  tagDeletedSuccessfully: "Tag deleted successfully.",
  newTagAddedSuccessfully: "Successfully added new tag.",
  tagUpdatedSuccessfully: "Tag updated successfully.",
  auditUpdatedSuccessfully: "Audit has been updated successfully.",
  auditSectionUpdatedSuccessfully: "Audit section has been saved successfully.",
  auditSavedSuccessfully: "Audit has been saved successfully.",
  auditSectionAddedSuccessfully: "Audit section has been added successfully.",
  auditTableAddedSuccessfully: "Table saved successfully.",
  auditSavingError: "Error in saving audit.",
  sections: "Sections",
  section: "Section",
  auditAssignments: "Audit Assignments",
  summaryTemplate: "Summary Template",
  auditSectionSavingError: "Error in audit section saving.",
  tableSavingError: "Error in table saving.",
  auditSectionDeleteSuccessfully:
    "Audit section has been deleted successfully.",
  errorInSectionDelete: "Error in deleting audit section.",
  deleteAuditSection: "Delete Audit Section",
  deleteSectionCofirmationMessage:
    "Are you sure you want to delete this audit section.",
  tagUpdatedError: "Error in tag update.",
  errorInTagSaving: "Error in tag saving.",
  errorInTagDelete: "Error in tag delete.",
  confirm: "Are you sure?",
  deleteSectionQuestion: " Delete Section Question",
  tagDeleteConfirmation: "Do you want to delete this tag?",
  auditPublished: "Audit published successfully.",
  yes: "Yes",
  no: "No",
  unsavedLostConfirmationMessage:
    "All unsaved changes would be lost, are you sure you want to continue?",
  searchHere: "Search Here",
  enterTagTitle: "Enter tag title here...",
  enterTextHere: "Enter text here...",
  enterTex: "Enter text",
  text: "Text",
  addTag: "Add Tag",
  documentTag: "Document tag:",
  documentType: "Document type:",
  documentNumber: "Document number:",
  view: "View",
  edit: "Edit",
  more: "More",
  viewPref: "View Preference:",
  customizable: "Customizable:",
  selectDocumentTag: "Select document tag ...",
  selectDocumentType: "Select document type ...",
  selectViewPref: "Select view preference ...",
  selectCustomizable: "Select customizable ...",
  enterDocNumber: "Enter document number ...",
  manageProgramManual: "Manage Program Manual",
  fileUploadError: "File Upload Error",
  fileUploadErrorMessage:
    "Unable to add any users. Download the error report to view and upload again after making corrections.",
  cancel: "Cancel",
  all: "All",
  preview: "Preview",
  saveDraft: "Save Draft",
  savePublish: "Save & Publish",
  yesPublish: "Yes, Publish",
  yesSaveDraft: "Yes, Save Draft",
  noteColon: "Note: ",
  note: "Note ",
  sureDraftAudit: "Are you sure you want to save audit as draft?",
  surePublishAudit: "Are you sure you want to publish this audit?",
  confirmationPublishAudit: "Published revision can not be edited.",
  title: "Title",
  enterTitle: "Enter title...",
  enterTableName: "Enter table name",
  enterColumnName: "Enter column name",
  enterPossiblePoints: "Enter possible points",
  required: "Req.",
  absolute: "Abs.",
  label: "Label",
  fieldType: "Field Type",
  fieldData: "Field Data",
  feedyardNamePlaceholder: "Enter feedyard name here",
  showInstruction: "Show Instructon",
  instruction: "Instruction",
  activityType: "Activity Type",
  requiredFrequency: "Required Frequency",
  frequencyCalendar: "Frequency Calendar",
  submissionCount: "Submission Count",
  instance: "Instance Based",
  permissions: "Permission",
  absolute: "Absolute",
  instructions: "Instructions",
  yes: "Yes",
  no: "No",
  true: "true",
  false: "false",
  changePassword: "Change Password",
  revision: "Revision",
  noTags: "No Tags Selected",
  requirements: "Requirements",
  instanceName: "Instance Name",
  viewActivity: "View Activity",
  instanceBased: "Instance Based",
  calendarFrequency: "Calendar Frequency",
  saveAndPublish: "Save & Publish",
  selectDate: "Select date",
  selectAll: "Select All",
  activityTypePlaceholder: "Select activity type",
  enterInstructions: "Enter Instructions here....",
  dashed: "dashed",
  addField: "Add Field",
  addOption: "ADD OPTION",
  auditNo: "Audit No.",
  auditNumber: "Audit Number",
  enterAuditNumber: "Enter audit number",
  enterFieldLabel: "Enter field label here",
  editActivity: "Edit Activity",
  updateActivityMessage: "Activity is successfully saved",
  emailPlaceholder: "Enter user email address here...",
  phonePlaceholder: "Enter phone Number here...",
  phoneNumberHint: "Example: +14151231234",
  invalidNumber: "Invalid phone Number",
  rolePlaceholder: "Select user role...",
  userFNamePlaceholder: "Enter user first name here...",
  userLNamePlaceholder: "Enter user last here here...",
  companyPlaceholder: "Enter the audit company here...",
  fName: "First Name",
  lName: "Last Name",
  company: "Company",
  confirmPassword: "Confirm Password",
  confPassPlaceholder: "Enter password here again...",
  enableDocApproval: "Enable Document Approaval",
  inActive: "Inactive",
  active: "Active",
  appJoinDate: "App Joining Date",
  userAddNotification: "User has been added successfully",
  userServerErrorNotification:
    "Some error occurred while processing. Please contact your system administrator",
  userEditNotification: "User has been updated successfully",
  userEditErrorNotification: "Error while updating a user",
  activityTitle: "Activity Title",
  activityStatus: "Activity(s) status updated",
  confirmation: "Do you want to continue?",
  activityStatusChangeConfirmation:
    "Are you sure you want to change the status of the selected activity(s)?",
  draftStatus:
    "Activity(s) having <b className='txt-black'>Draft</b> status will be <b className='txt-black'>Published</b>",
  publishStatus:
    "Activity(s) having <b className='txt-black'>Published</b> status will have a new <b className='txt-black'>Draft</b> version",
  unlock: "Unlock",
  userUnlockNotification: "User is successfully Unlock.",
  userNotLock: "User is not locked",
  userAlreadyUnlock: "User is Already Unlocked",
  activeStatus: "Active Status",
  updatedStatus: "Updated Status",
  userUnlockWarning: "Locked User(s) will become unlock",
  userUnlockDetail:
    "Please find the detail below for the selected user(s) for unlock.",
  unlockUserTitle: "Unlock User(s)",
  userStatusWarning: "User(s) with inactive status will become active.",
  userStatusInactiveWarning: "User(s) with active status will become inactive.",

  userStatuskDetail:
    "Please find the detail below for the selected users to update status.",
  userStatusSuccessNotification: "User(s) status has been updated Successfully",
  userStatusError: "Error while updating user(s)",
  activityTypeMessage: "Activity type is required",
  activityNumberMessage: "Activity number is required.",
  activityFrequencyMessage: "Activity frequency is required.",
  frequencyCalendarMessage: "Frequency calendar is required.",
  submissionCountMessage: "Submission count is required.",
  instanceBasedMessage: "Instance based is required",
  permissionMessage: "Permissions field is required",
  changeRowTypeConfirmation: "Are you sure you want to change the row type.",
  sno: "S No.",
  activityValidation:
    'Any special character from /\\:*?"<>|& is not allowed in activity number',
  fieldError: "You must add at least one field to this activity.",
  languageError:
    "You have not completed the activity fields in primary language (English).",
  instructionError: "Please provide instruction(s) for the field(s)",
  instanceError: "Please provide instance name",
  titleError: "Please provide activity title",
  feedyardmode: "Feedyard Mode",
  selectFeedyardMode: "Select feedyard mode",
  selectFeedyardTrainer: "Select trainer",
  feedyardInternalAuditor: "Select internal auditor",
  feedyardExternalAuditor: "Select external auditor",
  feedyardInternalReviewer: "Select internal reviewer",
  feedyardExternalReviewer: "Select external reviewer",
  feedyardFlmPlaceholder: "Select feedlot manager",
  feedyardDefaultActivites: "Select default activites to add",
  feedyardStatePlaceholder: "Select feedyard state",
  feedyardAddNotification: "New Feedyard Added Successfully",
  feedyardEditNotification: "Feedyard has been updated",
  feedyardAddErrorNotification: "Error in Feedyard Editing",
  editFeedyard: "Edit Feedyard",
  userNameReq: "Username is required.",
  fNameReq: "First name is required.",
  lNameReq: "Last name is required.",
  userRoleReq: "User role is required.",
  pwdReq: "Password is required.",
  pwdReqUpperCase: "Password should contain one upper case character.",
  pwdReqlowerCase: "Password should contain one lower case character.",
  pwdReqNumber: "Password should contain one number.",
  pwdReqLength: "Password should be at least 10 characters long.",
  pwdReqBothSame: "Both passwords must be same.",
  pwdReqSpace: "Password cannot contain blank space.",
  pwdReqConfirm: "Confirm Password is required.",
  addBulkuser: "Add Bulk Users",
  bulkuser: "Bulk Users",
  noRecordFound: " No Record Found",
  uplaodFile: "Upload File",
  downloadTemplateUser:
    "Please download a template for the users you want to add.",
  downloadTemplateNote1: "Before you start, download the template ",
  downloadTemplateNote2: "for the users you want to add.",
  dropExcelFile: "Click or drag file to this area to upload",
  or: "or",
  allowdFiles: "Only .xlsx files allowed",
  browseFile: "Browse File",
  userLockError: "Please select only locked user(s).",
  instanceError: "Instance name is required",
  titleError: "Activity title is required",
  feedyardName: "Feedyard Name",
  inRank: "In Rank?",
  trainers: "Trainer(s)",
  feedlotManagers: "Feedlot Manager(s)",
  state: "State",
  noFeedyard: "No feedyard is using this revision",
  feedyardTitle: "List of Feedyards Using Activity ",
  usernameMessage: "Username is required",
  passwordMessage: "Password is required",
  noPerformance: "No performance found in any feedyard",
  editSingleUser: "Edit User",
  unathorize: "You are not authorized to use Admin module.",
  forgetPassword: "Forgot Password?",
  submit: "Submit",
  back: "Back",
  activitiesCount1: "activity is selected.",
  activitiesCount2: "activites are selected.",
  activityPlaceholder: "Enter activity number",
  frequencyPlaceholder: "Select required frequency",
  instancPlaceholder: "Select instance based",
  permissionPlaceholder: "Select permission",
  tagsPlaceholder: "Select tag(s)",
  frequencyCalendarPlaceholder: "Select frequency calendar",
  submissionCountPlaceholder: "Enter submission count",
  notXlFile: "is not a xlsx file",
  bulkUserAdded: "Users have been uploaded successfully",
  uploadFailed: "Upload failed.",
  networkError: "You need to have an active internet connection!",
  noActivityFound: "No activity found matching",
  revision: "Revision",
  save: "Save",
  usersCount1: "user is selected.",
  usersCount2: "users are selected.",
  saveDraftConfirmation: "Do you really want to save draft?",
  publishConfirmation: "Do you really want to publish?",
  formulaError: "Cannot recognize formula",
  invalidFormula: "Invalid formula",
  formulaSync: "Formula synced in other languages",
  previewActivity: "Preview Activity",
  downloadTemplate: "Download Template",
  associateUser: "Associate User",
  binMessage:
    "Activity(s) having <b>Bin</b> status will have a new <b>Draft</b> version",
  emailInvalid: "Email address is invalid.",
  downloadErrorReport: "Download Error Report",
  markbin: "Do you really want to send to bin?",
  restoreActivity: "Do you really want to restore?",
  FYRevision: "FY Revision",
  publishedOn: "Published On",
  excelReport: "Excel Report",
  importActivity: "Import Activity",
  associateUserNote: "Enter the username to associate the user.",
  associateUserSuccNotification: "User has been associated successfully",
  disassociateUser: "Disassociate User",
  disassociateUserSuccNotification: "User is successfully saved.",
  startDate: "Start Date",
  endDate: "End Date",
  closedDate: "Closed Date",
  closed: "Closed",
  reviewInProgress: "Review In Progress",
  responseRequired: "Response Required",
  reviewed: "Reviewed",
  received: "Received",
  viewSummary: "View Summary",
  unassign: "Unassign",
  sendRequest: "Send Request",
  notRecordFound: "No records are found based on the selected criteria",
  reportSuccess: "Excel Report has been processed.",
  reportProcessSuccess: "Report has been processed successfully.",
  reportGenerationSuccess: "Report will be generated soon.",
  disassociateDescription: "Are you sure you want to unassociate the user?",
  confirmationMessage: "Confirmation",
  errorReportNotel1: "Review the error details by downloading error report.",
  errorReportNotel2: "Upload this excel file again after correction.",
  import: "import",
  new: "New",
  update: "Update",
  activityImport: "Activites imported successfully",
  notFound: "No data found",
  errorReportNotel1: "Review the error details by downloading error report.",
  errorReportNotel2: "Upload this excel file again after correction.",
  pens: "Pens",
  pending: "Pending",
  allusers: "All Users",
  locked: "Locked",
  fieldName: "Field Name",
  updateStatusConfirmationUser:
    "Are you sure you want to change the status of the selected user(s)?",
  usersWith: "User(s) with ",
  statusWillBecome: "status will become",
  updateActiveStatusConfirmationUser:
    "User(s) with Active status will become Inactive.",
  updateInActiveStatusConfirmationUser:
    "User(s) with Inactive status will become Active.",
  rule: "Rule",
  description: "Description",
  addRule: " Add Rule",
  saveRule: "Edit Rule",
  notificationLevel: "Violation Notification Level",
  feedyardNameValidateMsg: "Feedyard name is required.",
  feedyardAddressValidateMsg: "Feedyard address is required.",
  feedyardStateValidateMsg: "Feedyard state is required.",
  feedyardZipValidateMsg: "Feedyard ZIP code is required.",
  feedyardPhoneValidateMsg: "Feedyard phone number is required.",
  feedyardNarcValidateMsg: "Feedyard NARC is required.",
  backDatePrevEnable: "Backdate prevention enabled",
  date: "Date",
  days: "Day(s)",
  reason: "Reason",
  daysPlaceholder: "Enter No. of day(s) to allow backdate submissions",
  reasonPlaceholder: "Enter reason for backdate prevention disabled...",
  backdateRequired: "Backdate is required.",
  reasonRequired: "Reason is required.",
  modifyFeedyard: "Modify Feedyard",
  resetPassTitle: "Set New PIN/Password",
  reset: "Reset",
  pin: "PIN",
  restNote: "Please enter your new PIN/Password and then confirm PIN/Password.",
  resetPassSuccNotification: "Password has been successfully reset.",
  confirmPinPlaceholder: "Confirm PIN",
  pinPlaceholder: "Enter New PIN",
  pinReqConfirm: "Confirm PIN is required.",
  pinMessage: "PIN is required",
  pinValidate: "PIN should be 4 digits long.",
  pinReqBothSame: "Both PINs must be same.",
  fieldType: "Field Type",
  fieldMessage: "Field is Requried",
  operation: "Operation ...",
  values: "values...",
  value: "Value",
  logical: "Logical..",
  addCondtion: "Add Condition",
  field: "Field",
  fieldPlaceholder: "Select Field",
  selectFieldType: "Select field type",
  manageFeedyard: "Manage Feedyard(s)",
  deleteFieldRuleConfirmation: "Do you want to delete the rule?",
  deleteSectionQuestionConfirmation: "Do you want to delete question?",
  deleteRule: "Rule deleted successfully",
  importFeedyard:
    "Only activity(s) with published status will be imported. Do you want continue?",
  notificationMessage: "Notification level has been saved successfully.",
  pdfAndExcel: "PDF and Excel",
  downloads: "Downloads",
  excelReady: "Excel Ready",
  pdfReady: "PDF Ready",
  fileName: "File Name",
  pdfFileAvailable: "Following PDF files are available to download",
  excelFileAvailable: "Following Excel files are available to download",
  binMessageSuccess: "Activity send to bin successfully",
  restoreMessage: "Activity restored succuessfully",
  feedyardRevisionDateTime: "Feedyard Revision date & time:",
  instancePlaceholder: "Enter instance name here...",
  instanceValidation:
    'Any special character from /\\:*?"<>|& is not allowed in instance name',
  selectRevision: "Select revision",
  selectInstance: "select instance",
  instanceLabel: "Instance",
  instanceSelectErrorNotification:
    "Instance not selected, please select instance",
  removeInstancePlaceholder: "Enter your reason here...",
  instanceReason: "Please provide reason for removing the instance.",
  deleteInstance: "Instance has been deleted successfully.",
  removeInstance: "Remove Instance",
  remove: "Remove",
  deleteInstanceConfirmation: "Are you sure you want to remove this instance?",
  destinationFeedyard: "Destination Feedyard",
  feedyard: "Feedyard",
  feedyardCopyMessage: "Select feedyard where you want to copy the activity.",
  operationError: "Operation is required.",
  valueError: "Value is required.",
  importInFeedyard: "Import in Feedyard",
  instanceErrorMessage:
    "This instance name already exists. Choose a new unique name. Try adding a date or model number.",
  instanceValidationErrorMessage:
    "Instance name cannot contain any special character.",
  instanceCreationErrorMessage: "Unable to create new instance.",
  instanceCreationSuccessMessage: "Instance has been added successfully",
  noInstanceFound: "No instances are found",
  noRuleFound: "No rules are applied",
  importActivities: "Import Activities",
  userRole: "User Role",
  updateFeedyard: "Update Feedyard",
  copyActivity: "Copy Activity",
  copySection: "Copy Section",
  binActivity: "BIN Activity",
  restore: "Restore Activity",
  publishDraftStatus:
    "Activity(s) having both <b className='txt-black'>Draft</b> and <b className='txt-black'>Published</b> status will be <b className='txt-black'>Published</b>",
  update: "Update",
  viewSubmissions: "View",
  pdfReport: "PDF Report",
  excelSuccessProcessed: "Excel request is successfully processed",
  PDFSuccessProcessed: "PDF request is successfully processed",
  dateRange: "Date Range",
  passwordLinkExpireNote:
    "This link has expired.To request a new link click on ",
  supportEmail: "pbsupport@progressivebeef.com",
  contactUsAT: " or contact us at ",
  importErrorMessage: "There is no activity to import",
  noExcel: "There is no Excel to download",
  noPdf: "There is no PDF to download",
  noConnection: "No Internet Connection",
  offlineMessage: "You are offline. Please check your internet connection.",
  retry: "Retry",
  revisionDate: "Revision Date",
  auditNumber: "Audit Number",
  auditTitle: "Title",
  archived: "Archived",
  points: "Points",
  maxPoints: "Max Points",
  possiblePoints: "Possible Points",
  maxpossibleBonusPoints: "Maximum Possible Bonus Points",
  addQuestion: "Add Question",
  editQuestion: "Edit Question",
  scoreable: "Is Question Scorable?:",
  isAnnounced: "Is Announced",
  assignmentStatus: "Assignment Status",
  auditNotificationDate: "Audit Notification Date",
  questionPlaceholder: "Select Question",
  sopPlacehoder: "Enter SOP",
  addAnswerField: "ADD ANSWER FIELD",
  addColumn: "ADD COLUMN",
  columns: "Column(s)",
  filterSubmission: "Filter Submissions",
  filter: "Filter",
  clear: "Clear",
  deleteAnswerTemplate: "Do you want to delete this from Answer Template?",
  viewPDF: "View PDF",
  viewImage: "View Image",
  pdf: "PDF",
  image: "Image",
  deleteAnswerTemplateField: "Do you want to delete this from Answer Template?",
  deleteCheckFieldRule: "Do you want to delete this Check Field Rule?",
  negativeNumber: "Possible points must be greater then or equal to zero.",
  answerTemplatesMessages: "There are no answer field templates",
  templateFieldErrorMessage: "Template Field deleted successfully",
  columnsErrorMessage: "There are no columns",
  optionsErrorMessage: "There are no options",
  complianceDateRange: "Compliance Date Range",
  titleNotMoreHundredCharacters: "Title must not more than 100 characters.",
  negativeNumberValidator:
    "Possible points must be greater then or equal to zero.",
  saveAnswerTemplate: "Answer template has been saved successfully.",
  saveAnswerTemplateErrorMessage:
    "The answer template is not saved. Please save answer template first",
  deleteAnswerTemplateMessage:
    "Field has been removed successfully from the answer template.",
  labelErrorMessage: "Label cannot be empty",
  questionTypeErrorMessage: "Please select question type",
  sopErrorMessage: "SOP can not be empty",
  questionErrorMessage: "There are no questions.",
  feedyardRequired: "Feedyard is required.",
  auditRequired: "Audit Type is required.",
  selectFeedyard: "Select feedyard...",
  selectAuditType: "Select audit type...",
  auditType: "Audit Type",
  auditDate: "Audit Date",
  auditAssignment: "Audit Assignment",
  editAuditAssignment: "Edit Audit Assignment",
  unAssignAuditNote: "Are you sure you want to unassign this audit?",
  auditAssignToFeedyard: "Audit has been assigned successfully.",
  auditUpdatedAssignToFeedyard: "Assignment has been updated successfully.",
  auditUnAssignToFeedyard: "Audit has been unassigned successfully.",
  errorInAssignmentAudit: "Error in audit Assignment.",
  auditNumberRequire: "Audit number is required.",
  titleRequire: "Title is required.",
  tableNameRequire: "Table Name is required.",
  columnNameRequire: "Column Name is required.",
  possiblePointsRequire: "Possible points is required.",
  descriptionRequire: "Description is required.",
  viewImages: "View Images",
  auditNumberValidation:
    'Any special character from /\\:*?"<>|& is not allowed in audit number',
  auditTitleValidation:
    'Any special character from /\\:*?"<>|& is not allowed in audit title',
  sectionCopiedSuccessfully: "Section has been copied successfully.",
  wantToPublish: "Are you sure,you want to publish the audit?",
  publishAudit: "Publish Audit",
  publish: "Publish",
  addRow: "ADD ROW",
  isComplianceGreater: "Is Compliance = or > 95.0%? ",
  add: "Add",
  heading: "Heading",
  enterHeading: "Enter heading",
  enterNote: "Enter note here...",
  rowAddedSuccess: "Row has been added successfully.",
  errorInRowAdded: "Error Occur while adding row.",
  emptyTables: "There are no existing tables to display.",
  deleteTable: "Delete Table",
  deleteTableNote:
    "Are you sure you want to delete the table ? This is a permanent change, all saved settings will be lost. Do you want to continue ?",
  deleteTableSuccess: "Table has been deleted successfully.",
  deleteQuestonMessage: "Question has been deleted successfully.",
  notScored: "Not Scored",
  checkField: "Check Field",
  fieldNo: "Field S.No",
  value: "Value",
  logic: "Logic",
  Yes_No: "Yes/No",
  selectActivities: "Select Activities",
  saveAnswerTemplates: "Save Answer Template",
  orderSave: "Order has been updated successfully.",
  moveFieldMessage: "Field has been moved successfully.",
  copyFieldMessage: "Field has been copied successfully.",
  moveQuestionMessage: "Question has been moved successfully.",
  copyQuestionMessage: "Question has been copied successfully.",
  moveQuestion: "Move Question to",
  copyQuestion: "Copy Question to",
  noSection: "No section available",
  deleteTableRowNote: "Do you want to delete table row ?",
  deleteTableRow: "Delete Table Row",
  deleteTableRowSuccess: "Row has been deleted successfully.",
  editRow: "Edit Row",
  sNo: "SNo.",
  defaultValue: "Default Value",
  req: "Required",
  fieldLabel: "Field Label",
  dropQuestionErrorMessage:
    "Question can not be dropped outside the answer template",
  dropFieldErrorMessage: "Field can not be dropped outside the answer template",
  dropRowErrorMessage: "Row can not be dropped outside the table template",
  saveQuestion: "Question saved successfully",
  questions: "Questions",
  question: "Question",
  yes_no: "Yes/No",
  activitiesExceeded: "You cannot select more than 10 activities.",
  multipleChoice: "Multiple-Choice",
  moveToAnswerField: "Move Answer Field to",
  copyToAnswerField: "Copy Answer Field to",
  number: "Number",
  fetchCompliance: "Fetch Compliance",
  auditPoints: "Audit-Points",
  decimal: "Decimal",
  table: "Table",
  record: "Record",
  visual: "Visual",
  verbal: "Verbal",
  bonus: "Bonus",
  phoneNo: "Phone Number",
  rowEditSuccess: "Row has been updated successfully.",
  noAuditAssignment: "There are no audit assignments.",
  DateRequired: "Date is required.",
  copyAudit: "Copy Audit",
  auditCopyMessage: "Audit has been successfully copied.",
  fieldLabelPlaceholder: "Enter field label",
  defaultValuePlaceholder: "Enter default value",
  optionLabelPlaceholder: "Enter option label",
  columnLabelPlaceholder: "Enter column label",
  sourceCode: "Source Code",
  details: "Details",
  preAuditNotes: "PRE-AUDIT & AUDIT NOTES",
  skippedQuestion: "skipped Questions",
  nonConformances: "NON-CONFORMANCES",
  noSection: "There are no sections",
  assign: "Assign",
  dhName: "Fyodor Dyuzhenkov",
  dhInfo: " Feedyard Manager",
  dhEmail: " fyodordyuzhenkov@email.com",
  answered: "Answered",
  defaultDate: "Sep 25, 2019",
  start: "START",
  henryFeedyardName: "Henry Richardson Feedyard",
  dueToday: "Due Today",
  response: "RESPONSE",
  preAuditNote: "PRE-AUDIT NOTES",
  auditNotes: "AUDIT NOTES",
  feedyardAuditNotes: "FEEDYARD AUDIT NOTES",
  textHere: "Text Here..",
  addRecord: "ADD RECORD",
  addAttachment: "Add Attachment",
  or: "OR",
  takePhoto: "Take Photo",
  skip: "Skip",
  totalPoints: "Total Points",
  previous: "Previous",
  next: "Next",
  detail: "DETAIL",
  summary: "SUMMARY",
  preAudit: "PRE-AUDIT & AUDIT-NOTES",
  skipQuestions: "SKIPPED QUESTIONS",
  continue: "CONTINUE",
  FeedyardRankUpdate: "Feedyard rank permission updated",
  FeedyardRankUpdateError: "Error in feedyard rank permission updation",
  supervisor: "Supervisor",
  worker: "Worker",
  video: "Video",
  engSubTitle: "English Subtitle",
  spanSubTitle: "Spanish Subtitle",
  videoWarning: "Only .mp4 files upto 10 MB are supported.",
  onlyVttSupported: "Only .vtt files are supported.",
  activities: "Activities",
  noAppVideoFound: "No app navigation video found",
  manageAppNavigationVideos: "Manage App Navigation Videos",
  category: "Category",
  addVideo: "Add Video",
  editVideo: "Edit Video",
  addAppVideo: "Add App Navigation Video",
  editAppVideo: "Edit App Navigation Video",
  engTitle: "English Title",
  spanTitle: "Spanish Title",
  Greater_Than_Equal_To: "Greater Than Equal To",
  Greater_Than: "Greater Than",
  Equal_To: "Equal To",
  Less_Than_Equal_To: "Less Than Equal To",
  Less_Than: "Less Than",
  userRoleSeeVideo:
    "Every user with this role and above will be able to see this video",
  enterEngTitle: "Enter English title",
  enterSpanTitle: "Enter Spanish tiltle",
  selectVideoCategory: "Select category",
  selectVideoRole: "Select role",
  engTitleReq: "English title is required.",
  spanTitleReq: "Spanish title is required.",
  categoryReq: "Category is required.",
  roleReq: "Role is required.",
  appVideoAddSuccess: "App Navigation Video has been added successfully.",
  appVideoUpdateSuccess: "App Navigation Video has been updated successfully.",
  appVideoDeleteSuccess: "App Navigation Video has been deleted successfully.",
  deleteVideoNote: "Are you sure you want to delete app navigation video?",
  account: "Account",
  saveTableTemplate: "Save Table Template",
  activitesIncluded: "Activities Included",
  selectActivity: "Select Activities",
  selectFeedyard: "Select Feedyard",
  exportReport: "Export Report",
  error: "Error",
  fileSizeExceedNote:
    "Attached file size exceeds limits, please reduce size and try again.",
  clickUpload: "Click to Upload",
  startDatePlaceholder: "Select start date",
  endDatePlaceholder: "Select end date",
  feedyardPlaceholder: "Select feedyard",
  audit: "Audit",
  reportType: "Report Type",
  reportTypePlaceholder: "Select report type",
  download: "Download",
  format: "Format",
  excel: "Excel",
  csv: "CSV",
  selectAuditPlaceholder: "Select audit",
  auditSummaryFeedyard: "Audit Summary by Feedyard",
  auditScoresOverTime: "Audit Scores over time",
  endDateErrorMessage: "End date must be same or later than start date",
  exception:
    "Export method with window.location failed with the following exception:",
  excelReportSuccess: "Excel export request has been successfully processed.",
  networkException:
    "Some error occurred while processing. Please contact your system administrator",
  noRecordsFound: "No records are found based on the selected criteria",
  auditReport: "Audit Reports",
  manageTrainingVideos: "Manage Training Videos",
  pbVideoSaveNote: "Do you really want to save this training?",
  appTrainingVideoPrev: "App Training Video Preview",
  TrainingVideos: "Training Videos",
  exportReport: "Export Report",
  scheduleReport: "Schedule Report",
  manageScheduleReport: "Manage Schedule Reports",
  informationType: "Information Type",
  schedulingFrequency: "Scheduling Frequency",
  userRole: "User Roles",
  changeStatus: "Change Status",
  missingDocumentsActivities: "Missing Activities Revisions",
  backdatePreventionDisabled: "Backdate Prevention Disabled",
  documentsForApproval: "Documents for Approval",
  lockedUsers: "Locked Users",
  feedyardHaveOutdated: "Following feedyards have outdated activites.",
  feedyardHaveBackdateDisabled:
    "Following feedyards have backdate preventation disabled.",
  dashboardLockedUsers: "The following users are locked due to ",
  unsuccessfulLogin: "6 unsuccessful login",
  attempts: "attempts.",
  reportTitle: "Report Title",
  reportTitlePlaceholder: "Enter report title",
  infoType: "Info Type",
  selectInfoPlaceholder: "Select info type",
  frequencyKey: "Frequency Key",
  selectFrequencyPlaceholder: "Select Frequency",
  addSchedule: "Add Schedule",
  editSchedule: "Edit Schedule",
  scheduleSucessMessage: "Report schedule has been scheduled successfully.",
  scheduleDeleteMessage: "Report schedule has beed deleted successfully",
  deleteSchedule: "Delete Schedule",

  deleteScheduleConfirmation: "Are you sure you want to delete the schedule?",
  changeStatusConfirmation: "Are you sure you want to change the status of",
  scheduleStatusMessage:
    "Report schedule status has been changed successfully.",
  noCustomReport: "There are no custom report schedules found.",
  spanish: "Spanish",
  english: "English",
  videoSubUploadNote: "Please upload video and subtitle files",
  export: "Export",
  feedyardSelectionErrorMessage:
    "Selecting more than 15 feedyards is not allowed for this report.",
  infoTypeRequired: "Information Type is required",
  freqKeyRequired: "Frequency Key is required.",
  userRoleRequired: "User Role is required",
  startDateRequired: "Start Date is required.",
  endDateRequired: "End Date is required.",
  settings: "Settings",
  audits: "Audits",
  topScoringFeedyards: "TOP 5 SCORING FEEDYARDS",
  bottomScoringFeedyards: "BOTTOM 5 SCORING FEEDYARDS",
  filters: "Filters",
  apply: "Apply",
  from: "From",
  to: "To",
  plotDataNotFound: "Data not available to plot the Graph",
  BottomScoringFeedyards: "BOTTOM 5 SCORING FEEDYARDS",
  internalVs3rdParty: "Internal vs 3rd Party",
  selectChartPlaceholder: "Select chart",
  chart: "Chart",
  line: "Line",
  bar: "Bar",
  viewReport: "View Report",
  clear: "Clear",
  complianceReports: "Compliance Reports",
  noGraphData: "Data is not available to plot this graph",
  noCriteria: "Select any criteria to plot the graph",
  noCriteriaSelected: "No criteria is selected.",
  MP4Supported: "Files of type .MP4 are supported.",
  VttSupported: "Files of type .vtt are supported.",
  feedyardNotSelected: "Feedyard is not selected",
  activitesNotSelected: "Activities are not selected",
  endDateNotEqStartDate:
    "The end date should be equal or greater than the start date",
  auditStats: "Audit Stats",
  feedlotPerformance: "Feedlot Performance",
  TrainingVideosSaved: "Training has been saved successfully.",
  reportTitleValidation:
    'Any special character from /\\:*?"<>|& is not allowed in custom report.',
  ThirdParty: "3rd Party",
  docBuilder: "Document Builder",
  totalPoints: "Total Points",
  uploadVidVttFileNote: "Please upload Video file and subtitle files",
  timely_submission: "Timely Submission",
  sourceCode: "Source Code",
  noLockedUsers: "There is no locked user in the system at this time",
  feedyardSelection:
    "Select the feedyards in which you want to import the activity(s).",
  auditCopyInProgressMsg: "The process of copying the audit is in progress.",
  auditCopyInProgressHint:
    "The audit you're viewing is being copied. It'll be ready shortly.",
  auditCopyInProgressEditHint:
    "The audit you're trying to edit is being copied. It'll be ready shortly.",
  auditRevisionInProgressMsg:
    "The process of creating new revision of the audit is in progress.",
  auditRevisionInProgressHint:
    "New audit revision processing. Please wait and try again shortly.",
  sopNo: "SOP Number",
  sopTitle: "SOP Title",
  usedInAudits: "Used In Audits",
  noSopFound: "No SOPs found matching",
  manageSops: "Manage SOPs",
  addSop: "Add SOP",
  updateSop: "Update SOP",
  editSop: "Edit SOP",
  sops: "SOPs",
  enterSopNumber: "Enter SOP Number",
  enterSopTitle: "Enter SOP title here",
  sopNumberRequired: "SOP number is required.",
  sopTitleRequired: "SOP Title is required.",
  sopNumberValidation:
    'Any special character from /\\:*?"<>|& is not allowed in SOP number',
  sopTitleValidation:
    'Any special character from /\\:*?"<>|& is not allowed in SOP title',
  sopSavedSuccessfully: "SOP has been added successfully.",
  sopUpdatedSuccessfully: "SOP has been updated successfully.",
  sopSavingError: "Error in saving SOP.",
  removeSopNote: "Are you sure you want to remove this SOP?",
  sopRemovedSuccessfully: "SOP has been removed successfully.",
  searchSopPlaceholder: "Search SOP number or title",
  addNcAndCa: "Add NC & CA",
  ncsVerbiage: "Non-Conformances Verbiage",
  casVerbiage: "Corrective Action Verbiage",
  addNc: "NC",
  addCa: "CA",
  saveNCsAndCAs: "NCs and CAs have been saved successfully.",
  noQuestion: "No question available",
  copyNCTo: "Copy NC To",
  copyCATo: "Copy CA To",
  copyNCsAndCAs: "NC/CA have been copied successfully.",
  ncSpecialCharacterError: "Special characters <>[] are not allowed.",
  noQuestionSelected: "No Questions Selected",
  questionsPlaceholder: "Select Questions",
  donetxt: "Done",
  downloadTranslations: "Download Translations",
  downloadTranslationSuccess: "Activity translations downloaded.",
  downloadActivityTranslations:
    "Download translations for selected activities.",
  downloadActivityTranslationsSoon:
    "Activity translations file will be downloaded soon.",
  importActivityTranlations: "Import Activity Translations",
  translationsupdatedSuccess: "Translations updated successfully.",
  revertStatus: "Revert Status",
  revertStatusConfirmationMessage: "Current state data will be lost. Are you sure you want to revert the status?",
  auditStatusReverted: "Audit status updated successfully."
};

export default en;
